













import { Component, Vue } from 'vue-property-decorator';
import {mapGetters} from "vuex";
import Filecard from "@/components/hpcg.vue";

@Component({
  components: {
    Filecard
  },
  methods: {
    uploadFile(files: Array<File>) {
      this.$store.commit("addFiles", files);
    }
  },
  computed: {
    ...mapGetters(["files"])
  }
})
export default class Home extends Vue {}
