import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    files: Array<File>()
  },
  mutations: {
    addFile(state, file: File) {
      state.files.push(file)
    },
    addFiles(state, files: Array<File>) {
      state.files = state.files.concat(files);
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    files: state => state.files
  }
})
