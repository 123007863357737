<template>
  <div id="app">
    <cnav></cnav>
    <div id="main">
      <div id="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#main {
  display: flex;
  justify-content: center;
}
#content {
  max-width: 900px;
  width: 100%;
}
</style>
<script>
import Cnav from "@/components/cnav";
export default {
  components: {Cnav}
}
</script>
