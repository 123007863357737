<template>
  <b-navbar variant="primary" type="dark">
    <b-navbar-brand>
      HPCG Viewer
      <span class="text-white-50"> v{{version}}</span>
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
import packageJson from "../../package.json"
export default {
  name: "cnav",
  computed: {
    version() {
      return packageJson.version;
    }
  },
}
</script>

<style scoped>

</style>
