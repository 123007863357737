var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"header":"Processes"}},[_c('h4',[_vm._v(_vm._s(this.fileContent['Machine Summary']['Distributed Processes']+' Processes'))]),_c('h4',[_vm._v(_vm._s(this.fileContent['Machine Summary']['Threads per processes']+' Threads per process'))])]),_c('b-card',{attrs:{"header":"Problem Dimension","no-body":""}},[_c('b-table',{staticClass:"text-center",attrs:{"items":[
        Object.assign({}, {scope: 'local'},
          _vm.fileContent['Local Domain Dimensions']),
        {
          scope: 'global',
          nx: _vm.fileContent['Global Problem Dimensions']['Global nx'],
          ny: _vm.fileContent['Global Problem Dimensions']['Global ny'],
          nz: _vm.fileContent['Global Problem Dimensions']['Global nz'],
        }
        ]}})],1),_c('b-card',{attrs:{"header":"Problem Summary"}},[_c('ul',[_c('li',[_vm._v("Setup Time: "+_vm._s(_vm.fileContent['Setup Information']['Setup Time']))]),_c('li',[_vm._v("Equations: "+_vm._s(_vm.fileContent['Linear System Information']['Number of Equations']))]),_c('li',[_vm._v("Nonzero Terms: "+_vm._s(_vm.fileContent['Linear System Information']['Number of Nonzero Terms']))])])]),_c('b-card',{attrs:{"header":"Memory"}},[_c('h4',[_vm._v("Total Memory: "),_c('b',[_vm._v(_vm._s(_vm.fileContent['Memory Use Information']['Total memory used for data (Gbytes)'])+"GB")])]),_c('ul',[_c('li',[_vm._v("OptimizeProblem data: "+_vm._s(_vm.fileContent['Memory Use Information']['Memory used for OptimizeProblem data (Gbytes)'])+" GB")]),_c('li',[_vm._v("Bytes per equation: "+_vm._s(_vm.fileContent['Memory Use Information']['Bytes per equation (Total memory / Number of Equations)'])+" B")]),_c('li',[_vm._v("Linear system and CG: "+_vm._s(_vm.fileContent['Memory Use Information']['Memory used for linear system and CG (Gbytes)'])+" GB")])])]),_c('b-card',{attrs:{"header":"Result"}},[_c('h4',[_vm._v("HPCG Result: "),_c('b',[_vm._v(_vm._s(_vm.fileContent['__________ Final Summary __________']['HPCG result is VALID with a GFLOP/s rating of'])+" GFlops")])]),_c('h6',[_vm._v("HPCG 2.4 Result: "+_vm._s(_vm.fileContent['__________ Final Summary __________']['HPCG 2.4 Rating (for historical value) is'])+" GFlops")])]),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.modalID),expression:"modalID"}],staticClass:"w-100"},[_vm._v("Details")]),_c('b-modal',{attrs:{"id":_vm.modalID,"size":"xl","hide-footer":"","scrollable":""}},[_c('vue-json-pretty',{attrs:{"data":_vm.fileContent}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }