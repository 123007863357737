<template>
  <div>
    <b-card header="Processes">
      <h4>{{this.fileContent['Machine Summary']['Distributed Processes']+' Processes'}}</h4>
      <h4>{{this.fileContent['Machine Summary']['Threads per processes']+' Threads per process'}}</h4>
    </b-card>
    <b-card header="Problem Dimension" no-body>
      <b-table class="text-center" :items="[
          {
            scope: 'local',
            ...fileContent['Local Domain Dimensions']
          },
          {
            scope: 'global',
            nx: fileContent['Global Problem Dimensions']['Global nx'],
            ny: fileContent['Global Problem Dimensions']['Global ny'],
            nz: fileContent['Global Problem Dimensions']['Global nz'],
          }
          ]"></b-table>
    </b-card>
    <b-card header="Problem Summary">
      <ul>
        <li>Setup Time: {{fileContent['Setup Information']['Setup Time']}}</li>
        <li>Equations: {{fileContent['Linear System Information']['Number of Equations']}}</li>
        <li>Nonzero Terms: {{fileContent['Linear System Information']['Number of Nonzero Terms']}}</li>
      </ul>
    </b-card>
    <b-card header="Memory">
      <h4>Total Memory: <b>{{fileContent['Memory Use Information']['Total memory used for data (Gbytes)']}}GB</b></h4>
      <ul>
        <li>OptimizeProblem data: {{fileContent['Memory Use Information']['Memory used for OptimizeProblem data (Gbytes)']}} GB</li>
        <li>Bytes per equation: {{fileContent['Memory Use Information']['Bytes per equation (Total memory / Number of Equations)']}} B</li>
        <li>Linear system and CG: {{fileContent['Memory Use Information']['Memory used for linear system and CG (Gbytes)']}} GB</li>
      </ul>
    </b-card>
    <b-card header="Result">
      <h4>HPCG Result: <b>{{fileContent['__________ Final Summary __________']['HPCG result is VALID with a GFLOP/s rating of']}} GFlops</b></h4>
      <h6>HPCG 2.4 Result: {{fileContent['__________ Final Summary __________']['HPCG 2.4 Rating (for historical value) is']}} GFlops</h6>
    </b-card>
    <b-button class="w-100" v-b-modal="modalID">Details</b-button>
    <b-modal :id="modalID" size="xl" hide-footer scrollable>
      <vue-json-pretty :data="fileContent"></vue-json-pretty>
    </b-modal>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import yaml from "js-yaml"
import 'vue-json-pretty/lib/styles.css';
import VueJsonPretty from 'vue-json-pretty';

@Component({
  components: {
    VueJsonPretty
  },
  methods: {
    generateID(length) {
      const result = [];
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
      }
      return result.join('');
    }
  },
  computed: {
  },
  props: {
    file: File
  },
  data() {
    return {
      fileContent: null,
      modalID: ""
    }
  },
  created() {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const yamlS = event.target.result.replaceAll(/(HPCG-Benchmark\r?\n|3.0\r?\n|Coarse Grids([^#]|\n|\r)+(?=#))|\s{4}(?=HPCG 2.4)/g, "");
      this.fileContent = yaml.load(yamlS);
    });
    reader.readAsText(this.file)

    this.modalID = this.generateID(5);
  }

})
export default class Home extends Vue {}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 10px;
}
</style>
